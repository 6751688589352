













import Vue from "vue";
import { Component } from "vue-property-decorator";
import CSSModule from "@/store/modules/css-module";
import "material-design-icons/iconfont/material-icons.css";
import "devextreme/dist/css/dx.material.teal.light.compact.css";
import "devextreme/dist/css/dx.common.css";
import "@/assets/scss/main.scss";
import "vuesax/dist/vuesax.css"; // Vuesax
@Component({
  components: {},
})
export default class MainLayoutClientes extends Vue {
  public appTitle = process.env.VUE_APP_TITLE;
  public route: any;
  public refreshIntervalId = null;
  public varCounter = 0;
  public constructor() {
    super();
    this.bodystyle();
    Vue.prototype.$heightsection = this.$vuetify.breakpoint.height - 110;
  }
  public created() {
    this.$vuetify.theme.themes.light.primary = "#4CAF50"; // Green color
    this.$vuetify.theme.themes.light.secondary = "#4CAF50"; // Green color
    this.$vuetify.theme.themes.light.accent = "#4CAF50"; // Green color
    this.$vuetify.theme.themes.light.error = "red"; // Green color
  }
  public get animation_type() {
    this.route = this.$route;
    let animations = ["fade", "slide-fade", "bounce"];
    return animations[Math.floor(Math.random() * 3)];
  }
  public async bodystyle() {
    if (!CSSModule.cssimportados) {
      var files: string[] = [];
      // files.push('https://mymedicaldiet.es/wp-content/uploads/19.2.7.css.dx.common.css');
      // files.push('https://mymedicaldiet.es/wp-content/uploads/19.2.7.css.dx.material.orange.light.compact.css');
      //files.push('https://kit-pro.fontawesome.com/releases/v5.13.0/css/pro.min.css');
      files.push(
        "https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css"
      );
      for (let i = 0; i < files.length; i++) {
        /* const element = files[i];
         var styles = "@import url('" + files[i] + "');";*/
        var newSS = document.createElement("link");
        newSS.rel = "stylesheet";
        newSS.href = files[i]; //'data:text/css,' + escape(styles);
        document.getElementsByTagName("head")[0].appendChild(newSS);
      }
      const esMessages = await import(
        "devextreme/localization/messages/es.json"
      );
      const { locale, loadMessages } = await import("devextreme/localization");
      //import esMessages from "devextreme/localization/messages/es.json";
      //import { locale, loadMessages} from "devextreme/localization";

      loadMessages(esMessages);

      locale("es");
      // Styles: SCSS

      CSSModule.css_importados();
    }
  }
}
